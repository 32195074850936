import React from "react";
import ImageGallery from 'react-image-gallery';


export default function Screenshots() {
  const images = [
    {
      original: '/images/Import.png',
      thumbnail: '/images/Import_thumb.png',
    },
    {
      original: '/images/ImportSuccess.png',
      thumbnail: '/images/ImportSuccess_thumb.png',
    },
    {
      original: '/images/LinkGrabber.png',
      thumbnail: '/images/LinkGrabber_thumb.png',
    },
    {
      original: '/images/Delete.png',
      thumbnail: '/images/Delete_thumb.png',
    },
    {
      original: '/images/Search.png',
      thumbnail: '/images/Search_thumb.png',
    },
    {
      original: '/images/Emails.png',
      thumbnail: '/images/Emails_thumb.png',
    },
  ];

  return (

   <ImageGallery style={{width:'50%'}} items={images} />

  );
}

