import React from "react";


export default function Purchase() {

  return (
  <div className="content">

    <div className="single-item">
      <div className="left-set">
        <img src="./images/logo.svg" alt="" />
      </div>
      <div className="right-set">
        <div className="subname">LifeTime Licence</div>
        <div className="price">
          <span className="pricesmall">£ </span>99
        </div>
        <div className="description">
          <p>
            Import Accounts<br/>
            Delete Emails<br/>
            Link Grabber<br/>
            Clip Checker<br/>
            Search Emails<br/>
            Database Switching
          </p>
        </div>
        <a href="https://shoppy.gg/product/qPJEaaC" target="blank">
        <button className="purchasebutton">Buy Now</button>
        </a>
      </div>
    </div>
  </div>
  );
}
