import React from "react";
import { Link } from "react-router-dom";
import { FaHome, FaList, FaImages, FaShoppingCart, FaNewspaper } from "react-icons/fa";

const iconSize = "max(25px, 3vw)";

export default function Sidebar() {
  return (
    <div className="sidebar">
      <div className="sidebar-item-container">
        <div className="sidebar-row">
          <Link id="home" to="/">
            <FaHome className="sidebar-icon" size={iconSize} data-tip="Home" />
          </Link>
        </div>

        <div className="sidebar-row">
          <Link id="features" to="/features">
            <FaList
              className="sidebar-icon"
              size={iconSize}
              data-tip="Features"
            />
          </Link>
        </div>

        <div className="sidebar-row">
          <Link id="screenshots" to="/screenshots">
            <FaImages
              className="sidebar-icon"
              size={iconSize}
              data-tip="Screenshots"
            />
          </Link>
        </div>

        <div className="sidebar-row">
          <Link id="purchase" to="/purchase">
            <FaShoppingCart
              className="sidebar-icon"
              size={iconSize}
              data-tip="Purchase"
            />
          </Link>
        </div>

        <div className="sidebar-row">
          <Link id="terms" to="/terms">
            <FaNewspaper
              className="sidebar-icon"
              size={iconSize}
              data-tip="Terms + Conditions"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}
