import React from "react";

const Terms = () => {
  return (
    <div className="content">
      <div className="termsText">
        <h1 style={{ margin: 0, paddingBottom: 20, textAlign: "left" }}>
          <span className="termsHeader">
            Digital Products Terms and Conditions of Sale
          </span>
        </h1>
        <p>
          <span className="termsHeader">1.0 Introduction</span>
        </p>
        <p>
          <span>
            These terms and conditions set out the terms and conditions between
            you, the customer, and MailHawk (&ldquo;us&rdquo;,
            &ldquo;we&rdquo;), governing the use of our website and our
            downloadable digital software including the content therein (the
            &ldquo;products&rdquo;). Your use of our website, and purchase,
            download and use of our products, constitutes your full acceptance
            of these terms and conditions. If you do not agree with these terms
            and conditions, you should not use our website or purchase, download
            or use any of our products.
          </span>
        </p>
        <p>
          <span className="termsHeader">2.0 License and Use</span>
        </p>
        <p>
          <span>
            Your purchase of one of our products constitutes our granting to you
            of a non-exclusive, non-sublicensable, non-transferable license to
            download and access that product for the purpose of your own
            personal use and reference. You agree that under no circumstances
            shall you use, or permit to be used, any product other than for the
            aforesaid purpose. For the avoidance of doubt, you shall not copy,
            re-sell, sublicense, rent out, share or otherwise distribute any of
            our products, whether modified or not, to any third party. You agree
            not to use any of our products in a way which might be detrimental
            to us or damage our reputation.
          </span>
        </p>
        <p>
          <span className="termsHeader">3.0 Intellectual Property</span>
        </p>
        <p>
          <span>
            The products, whether modified or not, and all intellectual property
            and copyright contained therein, are and shall at all times remain
            our sole and exclusive property. You agree that under no
            circumstances, whether the product has been modified or not, shall
            you have or attempt to claim ownership of any intellectual property
            rights or copyright in the product.
          </span>
        </p>
        <p>
          <span className="termsHeader">4.0 Refunds and Chargebacks</span>
        </p>
        <p>
          <span>
            Once a product has been purchased by you, no right of cancellation
            or refund exists under the Consumer Protection (Distance Selling)
            Regulations 2000 due to the electronic nature of our products. Any
            refunds shall be at our sole and absolute discretion. You agree that
            under no circumstances whatsoever shall you initiate any chargebacks
            via your payment provider. You agree that any payments made by you
            for any of our products are final and may not be charged back. We
            reserve the right to alter any of our prices from time to time.
            Furthermore any attempt at a chargeback will instantly forfeit your
            licence and use of the product.
          </span>
        </p>
        <p>
          <span className="termsHeader">5.0 Warranties and Liability</span>
        </p>
        <p>
          <span>
            We make every effort to ensure that our products are accurate,
            authoritative and fit for the use of our customers. However, we take
            no responsibility whatsoever for the suitability of the product, and
            we provide no warranties as to the function or use of the product,
            whether express, implied or statutory, including without limitation
            any warranties of merchantability or fitness for particular purpose.
            You agree to indemnify us against all liabilities, claims, demands,
            expenses, actions, costs, damages, or loss arising out of your
            breach of these terms and conditions. Furthermore, we shall not be
            liable to you or any party for consequential, indirect, special or
            exemplary damages including but not limited to damages for loss of
            profits, business or anticipated benefits whether arising under
            tort, contract, negligence or otherwise whether or not foreseen,
            reasonably foreseeable or advised of the possibility of such
            damages.
          </span>
        </p>
        <p>
          <span className="termsHeader">6.0 General</span>
        </p>
        <p>
          <span>
            These terms and conditions constitute the entire agreement and
            understanding between you and us for the supply of downloadable
            digital products, and shall supersede any prior agreements whether
            made in writing, orally, implied or otherwise. The failure by us to
            exercise or enforce any right(s) under these terms and conditions
            shall not be deemed to be a waiver of any such right(s) or operate
            so as to bar the exercise or enforcement thereof at any time(s)
            thereafter, as a waiver of another or constitute a continuing
            waiver. You agree that monetary damages may not be a sufficient
            remedy for the damage which may accrue to us by reason of your
            breach of these terms and conditions, therefore we shall be entitled
            to seek injunctive relief to enforce the obligations contained
            herein. The unenforceability of any single provision within these
            terms and conditions shall not affect any other provision hereof.
            These terms and conditions, your acceptance thereof, and our
            relationship with you shall be governed by and construed in
            accordance with English law and both us and you irrevocably submit
            to the exclusive jurisdiction of the English courts over any claim,
            dispute or matter arising under or in connection with these terms
            and conditions or our relationship with you.
          </span>
        </p>
        <br />
        <br />
        <br />
        <br />
        <h1 style={{ margin: 0, paddingBottom: 20, textAlign: "left" }}>
          <span className="termsHeader">Privacy Policy</span>
        </h1>

        <p>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </p>

        <p>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>

        <h2>Interpretation and Definitions</h2>

        <p>
          <span className="termsHeader">Interpretation</span>
        </p>

        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>

        <p>
          <span className="termsHeader">Definitions</span>
        </p>

        <p>For the purposes of this Privacy Policy:</p>

        <ul>
          <li>
            <p>
              <strong>Account</strong> means a unique account created for You to
              access our Service or parts of our Service.
            </p>
          </li>

          <li>
            <p>
              <strong>Affiliate</strong> means an entity that controls, is
              controlled by or is under common control with a party, where
              &quot;control&quot; means ownership of 50% or more of the shares,
              equity interest or other securities entitled to vote for election
              of directors or other managing authority.
            </p>
          </li>

          <li>
            <p>
              <strong>Application</strong> means the software program provided
              by the Company downloaded by You on any electronic device, named
              MailHawk/HawkGen/HawkSoft
            </p>
          </li>

          <li>
            <p>
              <strong>Company</strong> (referred to as either &quot;the
              Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
              in this Agreement)
            </p>
          </li>

          <li>
            <p>
              <strong>Country</strong> refers to: United Kingdom
            </p>
          </li>

          <li>
            <p>
              <strong>Device</strong> means any device that can access the
              Service such as a computer, a cellphone or a digital tablet.
            </p>
          </li>

          <li>
            <p>
              <strong>Personal Data</strong> is any information that relates to
              an identified or identifiable individual.
            </p>
          </li>

          <li>
            <p>
              <strong>Service</strong> refers to the Application.
            </p>
          </li>

          <li>
            <p>
              <strong>Service Provider</strong> means any natural or legal
              person who processes the data on behalf of the Company. It refers
              to third-party companies or individuals employed by the Company to
              facilitate the Service, to provide the Service on behalf of the
              Company, to perform services related to the Service or to assist
              the Company in analyzing how the Service is used.
            </p>
          </li>

          <li>
            <p>
              <strong>Usage Data</strong> refers to data collected
              automatically, either generated by the use of the Service or from
              the Service infrastructure itself (for example, the duration of a
              page visit).
            </p>
          </li>

          <li>
            <p>
              <strong>You</strong> means the individual accessing or using the
              Service, or the company, or other legal entity on behalf of which
              such individual is accessing or using the Service, as applicable.
            </p>
          </li>
        </ul>

        <h2>Collecting and Using Your Personal Data</h2>

        <p>
          <span className="termsHeader">Types of Data Collected</span>
        </p>

        <p>
          <span className="termsHeader">Personal Data</span>
        </p>

        <p>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>

        <ul>
          <li>
            <p>Email address</p>
          </li>
          <li>
            <p>Usage Data</p>
          </li>
        </ul>

        <p>
          <span className="termsHeader">Usage Data</span>
        </p>

        <p>Usage Data is collected automatically when using the Service.</p>

        <p>
          Usage Data may include information such as Your Device&apos;s Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </p>

        <p>
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </p>

        <p>
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </p>

        <p>
          <span className="termsHeader">Use of Your Personal Data</span>
        </p>

        <p>The Company may use Personal Data for the following purposes:</p>

        <ul>
          <li>
            <p>
              To provide and maintain our Service, including to monitor the
              usage of our Service.
            </p>
          </li>

          <li>
            <p>
              To manage Your Account: to manage Your registration as a user of
              the Service. The Personal Data You provide can give You access to
              different functionalities of the Service that are available to You
              as a registered user.
            </p>
          </li>

          <li>
            <p>
              For the performance of a contract: the development, compliance and
              undertaking of the purchase contract for the products, items or
              services You have purchased or of any other contract with Us
              through the Service.
            </p>
          </li>

          <li>
            <p>
              To contact You: To contact You by email, telephone calls, SMS, or
              other equivalent forms of electronic communication, such as a
              mobile application&apos;s push notifications regarding updates or
              informative communications related to the functionalities,
              products or contracted services, including the security updates,
              when necessary or reasonable for their implementation.
            </p>
          </li>

          <li>
            <p>
              To provide You with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless You have opted not to receive such information.
            </p>
          </li>

          <li>
            <p>
              To manage Your requests: To attend and manage Your requests to Us.
            </p>
          </li>

          <li>
            <p>
              For business transfers: We may use Your information to evaluate or
              conduct a merger, divestiture, restructuring, reorganization,
              dissolution, or other sale or transfer of some or all of Our
              assets, whether as a going concern or as part of bankruptcy,
              liquidation, or similar proceeding, in which Personal Data held by
              Us about our Service users is among the assets transferred.
            </p>
          </li>

          <li>
            <p>
              For other purposes: We may use Your information for other
              purposes, such as data analysis, identifying usage trends,
              determining the effectiveness of our promotional campaigns and to
              evaluate and improve our Service, products, services, marketing
              and your experience.
            </p>
          </li>
        </ul>
        <p>
          We may share Your personal information in the following situations:
        </p>

        <ul>
          <li>
            <p>
              With Service Providers: We may share Your personal information
              with Service Providers to monitor and analyze the use of our
              Service, to contact You.
            </p>
          </li>
          <li>
            <p>
              For business transfers: We may share or transfer Your personal
              information in connection with, or during negotiations of, any
              merger, sale of Company assets, financing, or acquisition of all
              or a portion of Our business to another company.
            </p>
          </li>
          <li>
            <p>
              With Affiliates: We may share Your information with Our
              affiliates, in which case we will require those affiliates to
              honor this Privacy Policy. Affiliates include Our parent company
              and any other subsidiaries, joint venture partners or other
              companies that We control or that are under common control with
              Us.
            </p>
          </li>
          <li>
            <p>
              With business partners: We may share Your information with Our
              business partners to offer You certain products, services or
              promotions.
            </p>
          </li>
          <li>
            <p>
              With other users: when You share personal information or otherwise
              interact in the public areas with other users, such information
              may be viewed by all users and may be publicly distributed
              outside.
            </p>
          </li>
          <li>
            <p>
              With Your consent: We may disclose Your personal information for
              any other purpose with Your consent.
            </p>
          </li>
        </ul>

        <p>
          <span className="termsHeader">Retention of Your Personal Data</span>
        </p>

        <p>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </p>

        <p>
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </p>

        <p>
          <span className="termsHeader">Transfer of Your Personal Data</span>
        </p>

        <p>
          Your information, including Personal Data, is processed at the
          Company&apos;s operating offices and in any other places where the
          parties involved in the processing are located. It means that this
          information may be transferred to &mdash; and maintained on &mdash;
          computers located outside of Your state, province, country or other
          governmental jurisdiction where the data protection laws may differ
          than those from Your jurisdiction.
        </p>

        <p>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </p>

        <p>
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </p>

        <h2>Disclosure of Your Personal Data</h2>

        <p>
          <span className="termsHeader">Business Transactions</span>
        </p>

        <p>
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </p>

        <p>
          <span className="termsHeader">Law enforcement</span>
        </p>

        <p>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </p>

        <p>
          <span className="termsHeader">Other legal requirements</span>
        </p>

        <p>
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </p>

        <ul>
          <li>
            <p>Comply with a legal obligation</p>
          </li>
          <li>
            <p>Protect and defend the rights or property of the Company</p>
          </li>
          <li>
            <p>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </p>
          </li>
          <li>
            <p>
              Protect the personal safety of Users of the Service or the public
            </p>
          </li>
          <li>
            <p>Protect against legal liability</p>
          </li>
        </ul>

        <p>
          <span className="termsHeader">Security of Your Personal Data</span>
        </p>

        <p>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </p>

        <h2>Children&apos;s Privacy</h2>

        <p>
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 13 without verification of parental consent, We take
          steps to remove that information from Our servers.
        </p>

        <p>
          If We need to rely on consent as a legal basis for processing Your
          information and Your country requires consent from a parent, We may
          require Your parent&apos;s consent before We collect and use that
          information.
        </p>

        <h2>Links to Other Websites</h2>

        <p>
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party&apos;s site. We strongly advise You to review the
          Privacy Policy of every site You visit.
        </p>

        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>

        <h2>Changes to this Privacy Policy</h2>

        <p>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </p>

        <p>
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the
          &quot;Last updated&quot; date at the top of this Privacy Policy.
        </p>

        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>

        <h2>Contact Us</h2>

        <p>
          If you have any questions about this Privacy Policy, You can contact
          us:
        </p>

        <p>By email: privacy@mailhawk.co</p>
      </div>
    </div>
  );
};

export default Terms;
