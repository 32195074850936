import React from 'react'

const Home = () => {
    return (
        <div className="content">
            <img className="imageStyle" src="/images/logo.svg" alt="MailHawk" />

        </div>
    )
}

export default Home
