import React from "react";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Terms from "./pages/Terms";
import Screenshots from "./pages/Screenshots";
import Features from "./pages/Features";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Purchase from "./pages/Purchase";
import { CookiesProvider } from "react-cookie";
import SideBar from "./components/SideBar";
import ReactTooltip from 'react-tooltip';
import Discord from "./pages/Discord";

const isMobile = window.screen.width < 767 ? true: false;


export default function App() {
  return (
    <CookiesProvider>
      <ReactTooltip html={true} place="bottom" disable={isMobile} />
      <Router>
        <SideBar />
        <Header />


        <Switch>
          <Route exact path="/">
            <Home />
          </Route>

          <Route exact path="/terms">
            <Terms />
          </Route>

          <Route exact path="/purchase">
            <Purchase />
          </Route>

          <Route exact path="/screenshots">
            <Screenshots />  
          </Route>

          <Route exact path="/features">
            <Features /> 
          </Route>

          <Route exact path="/discordapi">
            <Discord />
          </Route>

        </Switch>
        <Footer />
      </Router>
    </CookiesProvider>
  );
}

