import React from "react";

const Header = () => {
  return (
    <>
      <div className="header">
        <img
          src="/images/header.gif"
          className="headerLogo"
          alt="logo"
          align="center"
        />
      </div>
    </>
  );
};

export default Header;
