import React, { useRef } from "react";
import ReactCardCarousel from "react-card-carousel";
import {
  FaUserPlus,
  FaTrash,
  FaLink,
  FaSearch,
  FaDatabase,
  FaBan,
  FaMailBulk,
  FaChevronRight,
  FaChevronLeft,
} from "react-icons/fa";

export default function Features() {
  const isMobile = window.screen.width < 767 ? true : false;

  const carRef = useRef(null);

  const buttonnext = () => carRef.current.next();
  const buttonprev = () => carRef.current.prev();

  const card_style_desktop = {
    height: "500px",
    width: "380px",
    paddingTop: "30px",
    textAlign: "center",
    color: "#181818",
    fontSize: "26px",
    fontWeight: "700",
    fontFamily: "Montserrat",
    background: "#F39C12",
    cursor: "pointer",
  };

  const card_style_mobile = {
    height: "380px",
    width: "260px",
    paddingTop: "30px",
    textAlign: "center",
    color: "#181818",
    fontSize: "18px",
    fontWeight: "700",
    fontFamily: "Montserrat",
    background: "#F39C12",
    cursor: "pointer",
  };

  const card_style = isMobile ? card_style_mobile : card_style_desktop;
  const spread = isMobile ? "narrow" : "wide";

  return (
    <div className="content">
      <div className="feature-card-container">
        <FaChevronLeft size="40" onClick={buttonprev} />
        <ReactCardCarousel
          ref={carRef}
          autoplay={false}
          autoplay_speed={3500}
          spread={spread}
        >
          <div style={card_style}>
            <span style={{ textShadow: "0px 1px 2px #181818" }}>
              Import Accounts
            </span>
            <br />
            <div className="feature-text">
              No longer do you have to manually add your accounts 1 by 1. With
              MailHawk's Bulk Importer you can import all of your Outlook,
              Hotmail, Gmail, Yahoo and AOL Accounts in seconds.
              <br />
              <br />
              Bulk Importer automatically checks for duplicates and validates
              your email addresses so you don't have to. Simply import a csv or
              txt file and Bulk Importer can import 1000 accounts in seconds.
            </div>
            <FaUserPlus size="80" className="feature-icon" />
          </div>
          <div style={card_style}>
            <span style={{ textShadow: "0px 1px 2px #181818" }}>
              Delete Emails
            </span>
            <br />
            <div className="feature-text">
              Sick of annoying newsletters clogging up your inbox? Slowing down
              Mailbird? With the Delete Emails feature you can delete emails
              from any domain in seconds.
              <br />
              <br />
              You can choose a date range or use wildcards to tailor the delete
              to your exact needs.
            </div>
            <FaTrash size="80" className="feature-icon" />
          </div>
          <div style={card_style}>
            <span style={{ textShadow: "0px 1px 2px #181818" }}>
              Link Grabber
            </span>
            <br />
            <div className="feature-text">
              With all your accounts in one place it is now even easier to get
              your raffle links. Just enter the link you want to grab, the date
              from when you want to look and MailHawk will grab your links, with
              the sender, date and save it into a csv file faster than any other
              bot.
              <br />
              <br />
              It's so fast that MailHawk can grab/scrape 10,000 links in less
              that 2 seconds.
            </div>
            <FaLink size="80" className="feature-icon" />
          </div>
          <div style={card_style}>
            <span style={{ textShadow: "0px 1px 2px #181818" }}>
              Clip Checker
            </span>
            <br />
            <div className="feature-text">
              An industry first. You can check all of your accounts to see if
              they are clipped at the touch of a button. MailHawk will tell you
              how many good accounts and how many clipped accounts you have.
              <br />
              <br />
              It will then export a file to the desktop of the clipped accounts
              for unclipping or deleting. It will even tell you which accounts
              are good but don't have IMAP enabled.
            </div>
            <FaBan size="80" className="feature-icon" />
          </div>
          <div style={card_style}>
            <span style={{ textShadow: "0px 1px 2px #181818" }}>
              Search Emails
            </span>
            <br />
            <div className="feature-text">
              A brand new game changing feature. You can search all your email
              accounts at once for senders or multiple keywords and then
              download to view them locally in seconds. No more Mailbird, no
              more forwarding, no more inboxes.
              <br />
            <br />
            Check for wins, easier and faster than ever before.
            </div>
            <FaSearch size="80" className="feature-icon" />
          </div>
          <div style={card_style}>
            <span style={{ textShadow: "0px 1px 2px #181818" }}>
              Unsubscriber
            </span>
            <br />
            <div className="feature-text">
              MailHawk's Unsubscriber is a game changer for email management.
              Unsubscriber can automatically unsubscribe your accounts from 
              thousands of popular newsletters.
              <br />
              <br />
              You can pick and choose which you want to unsubscribe from and
              which you'd like to keep. At the click of a button, annoying
              newsletters can become a thing of the past.
            </div>
            <FaMailBulk size="80" className="feature-icon" />
          </div>
          <div style={card_style}>
            <span style={{ textShadow: "0px 1px 2px #181818" }}>
              Database Switching
            </span>
            <br />
            <div className="feature-text">
              Is your email client or chrome running slow? Struggling to
              organise all of your email accounts? Well with Database Switching
              that's a thing of the past.
              <br />
              <br />
              You can create as many Databases as you want for your accounts and
              switch between them at the click of a button. This allows you to
              create Databases just for a 1 bot for example or maybe for 1
              raffle site.
            </div>
            <FaDatabase size="80" className="feature-icon" />
          </div>
        </ReactCardCarousel>
        <FaChevronRight size="40" onClick={buttonnext} />
      </div>
    </div>
  );
}
